<template>
    <div>
        <b-table
        slot="table"
        no-provider-filtering
        :items="itemsGrid"
        :fields="fieldsChartDonut"
        primary-key="id"
        table-class="text-nowrap"
        show-empty
        :busy.sync="isBusy"
        responsive
        sticky-header="170px"
        >
          <template #table-busy>
              <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
              </div>
          </template>

          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>
          
          <template #cell(rep)="data">
            $ {{ parseFloat(data.item.value).toFixed(2)  }}
          </template>
        </b-table>
    </div>
</template>
<script>
export default {
  components: {
  },
  props: {
    itemsGridCountry: {
      type: Array,
      default: null,
    },
    country:{
      type: String,
      default: null,
    }
  },
  data() {
    return {
        isBusy:false,
        itemsGrid:[],
        fieldsChartDonut:[
            {
                key: "id",
                label: "N",
                class: "text-center",
                sortable: false,
                visible: true,
            },
            {
                key: "name",
                label: "PRODUCT",
                class: "text-center",
                sortable: false,
                visible: true,
            },
            {
                key: "rep",
                label: "AMOUNT",
                class: "text-center",
                sortable: false,
                visible: true,
                thClass:'text-left'
            },
        ],
    };
  },
  async created() {
    if(this.country != null){
      let datas = this.itemsGridCountry.filter((item) => item.country === this.country);
      this.itemsGrid = datas;
    }
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    
  },
};
</script>