import { amgApi } from '@/service/axios'

class DashboardService {

    async getCountMostRequested(params) {
        const data = await amgApi.post('/logistics/dashboart/get-count-most-requested', params)
        return data.data
    }
    async getNumberMostRequestedProducts(params) {
        const data = await amgApi.post('/logistics/dashboart/get-number-most-requested-products', params)
        return data.data
    }
    async getMonthlyData(params){
        try {
            const data = await amgApi.post('/logistics/dashboart/get-monthly-data', params)
            return data.data
        } catch (error) {
            throw error;
        }
        
    }

    async getReport(params) {
        try {
            const data = await amgApi.post('/logistics/dashboard/get-report-logistic', params);
            return data;
        } catch (error) {
            throw error;
        }
    }
}
export default new DashboardService()