export default [
    {
      breakpoint: 367,
      options: {
        chart: {
          width: "100%",
          height: 300,
        },
        plotOptions: {
          pie: {
            offsetX: 0,
            donut: {
              labels: {
                total: {
                  fontSize: "15px",
                },
              },
            },
          },
        },
        legend: {
          offsetY: 0,
          offsetX: 0,
          fontSize: "14px",
          position: "right",
        },
      },
    },
    {
      breakpoint: 800,
      options: {
        chart: {
          width: "100%",
          height: 300,
        },
        plotOptions: {
          pie: {
            offsetX: 0,
            donut: {
              labels: {
                total: {
                  fontSize: "15px",
                },
              },
            },
          },
        },
        legend: {
          offsetY: 0,
          offsetX: 0,
          fontSize: "14px",
          position: "bottom",
        },
      },
    },
    {
      breakpoint: 992,
      options: {
        chart: {
          width: "100%",
          height: 300,
        },
        plotOptions: {
          pie: {
            width: "100%",
            height: 300,
            offsetX: 0,
            donut: {
              labels: {
                total: {
                  fontSize: "20px",
                },
                value:{
                  fontSize: '10px',
                }
              },
            },
          },
        },
        legend: {
          offsetY: 0,
          offsetX: 0,
          fontSize: "14px",
          position: "right",
        },
      },
    },
    {
      breakpoint: 1100,
      options: {
        chart: {
          width: "100%",
          height: 400,
        },
        plotOptions: {
          pie: {
            offsetX: 0,
          },
        },
        legend: {
          offsetY: 0,
          offsetX: 0,
          fontSize: "14px",
          position: "right",
        },
      },
    },
    {
      breakpoint: 1200,
      options: {
        chart: {
          width: "100%",
          height: 300,
        },
      },
    },
    {
      breakpoint: 1300,
      options: {
        chart: {
          width: 330,
          height: 300,
        },
        plotOptions: {
          pie: {
            offsetX: -20,
            donut: {
              labels: {
                value: {
                  show: true,
                  offsetY:-5,
                  fontSize: "7px",
                },
              }
            }
          }
        },   
      },
    },
    {
      breakpoint: 1400,
      options: {
        chart: {
          width: 350,
          height: 300,
        },
        plotOptions: {
          pie: {
            offsetX: -20,
            donut: {
              labels: {
                show: true,
                value: {
                  show: true,
                  offsetY: 0,
                  fontSize: "10px",
                },
                total: {
                  fontSize: "15px",
                },
              },
            },
          },
        },
        legend: {
          offsetY: 0,
          offsetX: 0,
          fontSize: "14px",
          position: "right",
          height: 100,
        },
      },
    },
    {
      breakpoint: 1600,
      options: {
        chart: {
          width: "100%",
          height: 300,
        },
        plotOptions: {
          pie: {
            offsetX: -20,
            donut: {
              labels: {
                total: {
                  fontSize: "20px",
                },
                value:{
                  fontSize: '11px',
                  offsetY:0,
                }
              },
            },
          },
        },
        legend: {
          offsetY: 0,
          offsetX: 0,
          fontSize: "14px",
        },
      },
    },
    {
      breakpoint: 1800,
      options: {
        chart: {
          width: "92%",
          height: 300,
        },
      },
    },
    {
      breakpoint: 11125600,
      options: {
        chart: {
          width: "100%",
          height: "95%",
        },
        plotOptions: {
          pie: {
            offsetX: -10,
            donut: {
              labels: {
                show: true,
                total: {
                  fontSize: "15px",
                },
                value:{
                  fontSize: '15px',
                  offsetY:-5,
                }
              },
            },
          },
        },
        legend: {
          show: true,
          offsetY: 0,
          offsetX: 0,
          fontSize: "14px",
          horizontalAlign: "right",
          itemMargin: {
            horizontal: 0,
            vertical: 5,
          },
        },
      },
    },
];