import { amgApi } from "@/service/axios";


class SettingsService {
   async getPackings(){
    try {
        const data = await amgApi.get("logistics/get-packings");
        return data;
    } catch (error) {
        console.log("Error counterOffer");
    }
   }
   async getCategory(){
    try {
        const data = await amgApi.get("logistics/get-category");
        return data;
    } catch (error) {
        console.log("Error counterOffer");
    }
   }
   async getSubCategory(){
    try {
        const data = await amgApi.get("logistics/get-sub-category");
        return data;
    } catch (error) {
        console.log("Error counterOffer");
    }
   }
   async getSpecifications(){
    try {
        const data = await amgApi.get("logistics/get-specifications");
        return data;
    } catch (error) {
        console.log("Error counterOffer");
    }
   }
   async getCriteria(){
    try {
        const data = await amgApi.get("logistics/get-criteria");
        return data;
    } catch (error) {
        console.log("Error counterOffer");
    }
   }
    async saveCategory(params) {
        try {
            const data = await amgApi.post("logistics/add-category", params);
            return data;
        } catch (error) {
            console.log("Error counterOffer");
        }
    }
    async saveSubCategory (params){
        try {
            const data = await amgApi.post("logistics/add-sub-category", params);
            return data;
        } catch (error) {
            console.log("Error counterOffer");
        }
    }
    async savePackings(params) {
        try {
            const data = await amgApi.post("logistics/add-packings", params);
            return data;
        } catch (error) {
            console.log("Error counterOffer");
        }
    }
    async saveSpecification(params) {
        try {
            const data = await amgApi.post("logistics/add-specification", params);
            return data;
        } catch (error) {
            console.log("Error counterOffer");
        }
    }
    async saveCriteria(params) {
        try {
            const data = await amgApi.post("logistics/add-criteria", params);
            return data;
        } catch (error) {
            console.log("Error counterOffer");
        }
    }
    async updatePackings(params) {
        try {
            const data = await amgApi.post("logistics/update-packings", params);
            return data;
        } catch (error) {
            console.log("Error counterOffer");
        }
    }
    async updateCategory(params) {
        try {
            const data = await amgApi.post("logistics/update-category", params);
            return data;
        } catch (error) {
            console.log("Error counterOffer");
        }
    }
    async updateSubCategory(params) {
        try {
            const data = await amgApi.post("logistics/update-sub-category", params);
            return data;
        } catch (error) {
            console.log("Error counterOffer");
        }
    }
    async updateSpecifications(params) {
        try {
            const data = await amgApi.post("logistics/update-specifications", params);
            return data;
        } catch (error) {
            console.log("Error counterOffer");
        }
    }
    async updateCriteria(params) {
        try {
            const data = await amgApi.post("logistics/update-criteria", params);
            return data;
        } catch (error) {
            console.log("Error counterOffer");
        }
    }
    async deletePacking(params) {
        try {
            const data = await amgApi.post("logistics/delete-packings", params);
            return data;
        } catch (error) {
            console.log("Error counterOffer");
        }
    }
    async deleteCategory(params) {
        try {
            const data = await amgApi.post("logistics/delete-category", params);
            return data;
        } catch (error) {
            console.log("Error counterOffer");
        }
    }
    async deleteSubCategory(params) {
        try {
            const data = await amgApi.post("logistics/delete-sub-category", params);
            return data;
        } catch (error) {
            console.log("Error counterOffer");
        }
    }
    async deleteSpecifications(params) {
        try {
            const data = await amgApi.post("logistics/delete-specifications", params);
            return data;
        } catch (error) {
            console.log("Error counterOffer");
        }
    }
    async deleteCriteria(params) {
        try {
            const data = await amgApi.post("logistics/delete-criteria", params);
            return data;
        } catch (error) {
            console.log("Error counterOffer");
        }
    }
}

export default new SettingsService();