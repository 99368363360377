<template>
  <div>
    <header-slot></header-slot>
    <div>
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
          <b-card :class="isDarkSkin ? '' : 'filters'">
            <b-row>
              <b-col cols="12" sm="12" md="10" lg="10" xl="10">
                <b-row>
                  <b-col cols="12" sm="12" md="3" lg="3" xl="3" class="h_40">
                    <v-select
                      class="vselect"
                      v-model="filter.yearSelect"
                      :reduce="(option) => option.id"
                      :options="year"
                      :clearable="false"
                      placeholder="Year"
                      label="value"
                      @input="changeYearMonths()"
                    />
                  </b-col>

                  <b-col cols="12" sm="12" md="3" lg="3" xl="3" class="h_40">
                    <v-select
                      class="vselect"
                      v-model="filter.monthSelected"
                      :options="months"
                      :reduce="(val) => val.id"
                      :clearable="false"
                      placeholder="Month"
                      label="value"
                      @input="changeYearMonths()"
                    />
                  </b-col>

                  <b-col cols="12" sm="12" md="3" lg="3" xl="3" class="h_40">
                    <v-select
                      class="vselect"
                      v-model="filter.category"
                      :reduce="(val) => val.id"
                      :options="categories"
                      placeholder="Category..."
                      label="name"
                      @input="selectHandler"
                    />
                  </b-col>

                  <b-col cols="12" sm="12" md="3" lg="3" xl="3" class="h_40">
                    <v-select
                      class="vselect"
                      v-model="filter.subcategory"
                      :reduce="(option) => option.id"
                      placeholder="Subcategory..."
                      :options="subcategories"
                      label="name"
                    />
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="12" sm="12" md="2" lg="2" xl="2" class="mb-sm-0">
                <b-button
                  variant="outline-primary"
                  class="w-100"
                  @click="searchReport"
                  >Search</b-button
                >
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col cols="12" sm="12" md="12" lg="7" xl="8">
          <div style="min-height: 330px; height: 96%" class="card card-body">
            <vue-apex-charts
              type="line"
              ref="towerChart"
              height="90%"
              width="100%"
              :options="options"
              :series="options.series"
            />
          </div>
        </b-col>

        <b-col cols="12" sm="12" md="12" lg="5" xl="4">
          <b-row>
            <b-col cols="12" sm="12" md="6" lg="12" xl="12">
              <b-card>
                <b-row class="border-bottom pb-1">
                  <b-col cols="7">
                    <b-row>
                      <b-col
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        class="pl-1 pr-0 px-0 d-flex align-content-center"
                      >
                        <img
                          src="@/assets/images/icons/flag-eeuu-radius.png"
                          alt="logo"
                          class="flag"
                        />
                        <span class="title_echart"> United States </span>
                        <span class="subtitle_echart">
                          {{ months[filter.monthSelected].value }},
                          {{ filter.yearSelect }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="5">
                    <b-row>
                      <b-col cols="1" sm="1" md="1" lg="1" xl="1" class="px-0">
                      </b-col>
                      <b-col
                        cols="11"
                        sm="11"
                        md="11"
                        lg="11"
                        xl="11"
                        class="pl-1"
                      >
                        <v-select
                          v-model="selectedType"
                          :options="optionsViewChart"
                          :reduce="(option) => option.id"
                          label="name"
                          class="ml-1 w-100"
                          :clearable="false"
                          style="font-size: 10px"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" class="mt-2">
                    <vue-apex-charts
                      :class="selectedType == 1 ? 'd-block' : 'd-none'"
                      ref="chart_usa"
                      type="donut"
                      height="370"
                      width="100%"
                      :options="options_doughnut_usa"
                      :series="options_doughnut_usa.series"
                    />

                    <grid-detail-country
                      v-if="selectedType == 2"
                      :itemsGridCountry="itemsGridCountry"
                      country="usa"
                    ></grid-detail-country>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <b-col cols="12" sm="12" md="6" lg="12" xl="12">
              <b-card>
                <b-row class="border-bottom">
                  <b-col cols="7">
                    <b-row>
                      <b-col
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        class="pl-1 pr-0 px-0 d-flex align-content-center"
                      >
                        <img
                          src="@/assets/images/icons/peru-flag.png"
                          alt="flag-peru"
                          class="flag"
                        />
                        <span class="title_echart"> Peru </span>
                        <span class="subtitle_echart">
                          {{ months[filter.monthSelected].value }},
                          {{ filter.yearSelect }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="5">
                    <b-row>
                      <b-col
                        cols="11"
                        sm="11"
                        md="11"
                        lg="11"
                        xl="11"
                        class="pl-1"
                      >
                        <v-select
                          v-model="selectedTypePeru"
                          :options="optionsViewChart"
                          :reduce="(option) => option.id"
                          label="name"
                          class="ml-1 w-100 pb-1"
                          :clearable="false"
                          style="font-size: 10px"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" class="mt-2">
                    <vue-apex-charts
                      :class="selectedTypePeru == 1 ? 'd-block' : 'd-none'"
                      ref="chart_peru"
                      type="donut"
                      height="370"
                      width="100%"
                      :options="options_doughnut_peru"
                      :series="options_doughnut_peru.series"
                    />

                    <grid-detail-country
                      v-if="selectedTypePeru == 2"
                      :itemsGridCountry="itemsGridCountry"
                      country="peru"
                    ></grid-detail-country>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="6" class="mt-1" v-if="isCeo || isChief || isSupervisor">
          <DepartmentExpenses
            :showDate="true"
            :yearDash="filter.yearSelect"
            :monthDash="filter.monthSelected"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import AppEchartDoughnut from "@core/components/charts/echart/AppEchartDoughnut.vue";
import AppEchartLineLogistic from "@/views/logistic/views/dashboard/components/AppEchartLineLogistic.vue";

import SettingsService from "@/views/logistic/views/settings/services/settings.service.js";
import LogisticInventoryService from "@/views/logistic/views/inventory2/services/logistic-inventory.service.js";
import DashboardService from "./service/dashboard.service";
import GridDetailCountry from "@/views/logistic/views/dashboard/components/GridDetailCountry.vue";
import chartResponsiveUsa from "@/views/logistic/views/dashboard/dashboard.us.chart.js";
import chartResponsivePe from "@/views/logistic/views/dashboard/dashboard.pe.chart.js";
import DepartmentExpenses from "@/views/commons/expenses/components/department/DepartmentExpenses.vue";

export default {
  components: {
    "app-echart-line-logistic": AppEchartLineLogistic,
    "app-echart-doughnut": AppEchartDoughnut,
    "grid-detail-country": GridDetailCountry,
    VueApexCharts,
    DepartmentExpenses
  },
  data() {
    return {
      year: [
        { value: "2017", id: 2017 },
        { value: "2018", id: 2018 },
        { value: "2019", id: 2019 },
        { value: "2020", id: 2020 },
        { value: "2021", id: 2021 },
        { value: "2022", id: 2022 },
        { value: "2023", id: 2023 },
      ],
      months: [{ user_name: "2028", id: "01" }],
      categories: [],
      subcategories: [],
      filter: {
        yearSelect: null,
        monthSelected: null,
        category: null,
        subcategory: null,
      },
      country: [
        { country_name: "PERU", id: "PERU" },
        { country_name: "USA", id: "USA" },
      ],
      countrySelect: "USA",
      module: [
        { module_name: "Equipments", id: "equi" },
        { module_name: "Products Inventory", id: "products" },
        { module_name: "Merchandising", id: "merchand" },
      ],
      moduleSelect: "",
      status_inventory: false,

      //charts line
      keyChart: 0,
      seriesPie: [],
      options: {
        series: [
          {
            name: "",
            type: "column",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: "",
            type: "line",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
        chart: {
          height: "100%",
          type: "line",
          toolbar: {
            show: true,
            tools: {
              download: false,
              pan: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              reset: false,
              menu: false,
            },
          },
        },
        stroke: {
          width: [0, 4],
        },
        title: {
          text: "", //CalendarIcon
          style: {
            fontSize: "24px",
            fontWeight: "bold",
            fontFamily: "arial",
            color: "#007DC8",
          },
        },
        subtitle: {
          text: "",
          align: "right",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "19px",
            fontWeight: "800",
            fontFamily: "Rubik",
            color: "#373d3f",
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
          style: {
            fontSize: "12px",
            colors: ["rgba(255, 255, 255, 0)"],
          },
          formatter: function (val, opts) {
            return "$ " + parseFloat(val).toFixed(2);
          },
          offsetY: -7,
          background: {
            enabled: true,
            foreColor: "#800080",
            opacity: 1,
            borderColor: "rgba(255, 255, 255, 0)",
          },
        },
        decimalsInFloat: 5,
        xaxis: {
          show: true,
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          labels: {
            style: {
              colors: "#373d3f",
            },
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            formatter: this.formatterFunc,
            style: {
              colors: ["#373d3f"],
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },

      selectedType: 1,
      optionsViewChart: [
        { id: 1, name: "GRAPHIC" },
        { id: 2, name: "LIST" },
      ],
      itemsGridCountry: [],
      selectedTypePeru: 1,

      options_doughnut_peru: {
        labels: [],
        series: [],
        chart: {
          width: 380,
          type: "donut",
          dropShadow: {
            enabled: true,
            color: "#111",
            top: -1,
            left: 3,
            blur: 3,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "80%",
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                  fontSize: "30px",
                },
              },
            },
          },
        },
        stroke: {
          show: true,
          curve: ["smooth", "straight", "stepline"],
          lineCap: "butt",
          width: 0,
          dashArray: 0,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "solid",
          opacity: 1,
          pattern: {
            enabled: false,
            style: ["verticalLines"],
          },
        },
        legend: {
          formatter: function (val, opts) {
            const formattedNumber = new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(opts.w.globals.series[opts.seriesIndex]);
            return val + " ( $ " + formattedNumber + " )";
          },
          position: "right",
          offsetY: -10,
          offsetX: 105,
          fontSize: "23px",
          height: 105,
          itemMargin: {
            horizontal: 2,
            vertical: 5,
          },
          labels: {
            colors: "#373d3f",
          },
        },
        title: {
          text: "",
        },
        responsive: chartResponsivePe,
      },
      options_doughnut_usa: {
        labels: [],
        series: [],
        chart: {
          width: 380,
          type: "donut",
          dropShadow: {
            enabled: true,
            color: "#111",
            top: -1,
            left: 3,
            blur: 3,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "80%",
              labels: {
                show: true,
                value: {
                  show: true,
                },
                total: {
                  showAlways: true,
                  show: true,
                  fontSize: "30px",
                },
              },
            },
          },
        },
        stroke: {
          show: true,
          curve: ["smooth", "straight", "stepline"],
          lineCap: "butt",
          width: 0,
          dashArray: 0,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "solid",
          opacity: 1,
          pattern: {
            enabled: false,
            style: ["verticalLines"],
          },
        },
        legend: {
          formatter: function (val, opts) {
            const formattedNumber = new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(opts.w.globals.series[opts.seriesIndex]);
            return val + " ( $ " + formattedNumber + " )";
          },
          position: "right",
          offsetY: -10,
          offsetX: 105,
          fontSize: "23px",
          height: 105,
          itemMargin: {
            horizontal: 2,
            vertical: 5,
          },
          labels: {
            colors: "#373d3f",
          },
        },
        title: {
          text: "",
        },
        responsive: chartResponsiveUsa,
      },
    };
  },
  watch: {
    isDarkSkin(oldVal, newVal) {
      this.changeChartCountry();
    },
  },
  created() {
    const monthsYear = moment.months();
    monthsYear.map((month, key) => {
      this.months.push({ id: key + 1, value: month });
    });
    this.filter.yearSelect = moment().year();
    this.filter.monthSelected = moment().month() + 1;
    try {
      this.addPreloader();
      Promise.all([this.getCategories(), this.getMonthlyData()]);
    } catch (error) {
      console.log(error);
    } finally {
      this.removePreloader();
      this.searchReport();
    }
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async getMonthlyData() {
      const params = {
        year: this.filter.yearSelect,
        category_id: this.filter.category,
        subcategory_id: this.filter.subcategory,
      };
      const data = await DashboardService.getMonthlyData(params);
      const totalDollarAmounts = data.map((item) =>
        parseFloat(item.monthly_amounts)
      );
      this.options.series[0].data = totalDollarAmounts;
      this.options.series[1].data = totalDollarAmounts;
      this.$refs.towerChart?.updateOptions({
        data: totalDollarAmounts,
      });

      //total charts
      let total = totalDollarAmounts.reduce(
        (acumulador, elemento) => acumulador + elemento,
        0
      );
      const formattedNumber = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(total);
      let options = {
        subtitle: { text: "Expenses Accumulated Total: $" + formattedNumber },
      };
      this.$refs.towerChart?.updateOptions(options);
    },
    async searchReport() {
      try {
        this.addPreloader();
        const params = {
          year: this.filter.yearSelect,
          month: this.filter.monthSelected,
          category_id: this.filter.category,
          subcategory_id: this.filter.subcategory,
        };
        this.getMonthlyData();
        const { data } = await DashboardService.getReport(params);

        const labelsPeru = [];
        const labelsUsa = [];
        const seriesPeru = [];
        const seriesUsa = [];
        const dataGrilla = [];

        data.map((row) => {
          labelsPeru.push(row.name);
          labelsUsa.push(row.name);
          row.rep = row.rep == null ? [] : JSON.parse(row.rep);
          if (row.rep.length == 0) {
            seriesPeru.push(0);
            seriesUsa.push(0);
            dataGrilla.push({
              country: "peru",
              name: row.name,
              value: 0,
            });
            dataGrilla.push({
              country: "usa",
              name: row.name,
              value: 0,
            });
          } else {
            const _usa = row.rep.find((r) => r.country_id == 75); // if USA
            const _peru = row.rep.find((r) => r.country_id == 173); // if PERU
            dataGrilla.push({
              country: "peru",
              name: row.name,
              value: _peru ? _peru.total_dollar : 0,
            });
            dataGrilla.push({
              country: "usa",
              name: row.name,
              value: _usa ? _usa.total_dollar : 0,
            });
            seriesPeru.push(_peru ? _peru.total_dollar : 0);
            seriesUsa.push(_usa ? _usa.total_dollar : 0);
          }
        });

        this.itemsGridCountry = dataGrilla;
        const total_pe = seriesPeru.reduce((a, b) => a + b, 0);
        const total_usa = seriesUsa.reduce((a, b) => a + b, 0);

        const options_peru = {
          labels: labelsPeru,
          series: total_pe == 0 ? [0.000001, 0, 0, 0, 0, 0, 0] : seriesPeru,
          plotOptions: {
            pie: {
              donut: {
                size: "80%",
                labels: {
                  show: true,
                  value: {
                    show: true,
                  },
                  total: {
                    showAlways: true,
                    show: true,
                    formatter: function (val) {
                      const sumResult = val.config.series.reduce(
                        (acc, num) => acc + num,
                        0
                      );
                      return (
                        "$ " +
                        sumResult.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      );
                    },
                  },
                },
              },
            },
          },
          fill: {
            colors: ["#ff0"],
          },
        };
        if (total_pe < 1) {
          options_peru.fill.colors = ["rgba(255, 0, 0, 0.5)"];
        } else {
          delete options_peru.fill;
        }
        this.$refs.chart_peru?.updateOptions(options_peru);

        const options_usa = {
          labels: labelsUsa,
          series: total_usa == 0 ? [0.000001, 0, 0, 0, 0, 0, 0] : seriesUsa,
          plotOptions: {
            pie: {
              donut: {
                size: "80%",
                labels: {
                  show: true,
                  value: {
                    show: true,
                  },
                  total: {
                    showAlways: true,
                    show: true,
                    formatter: function (val) {
                      const sumResult = val.config.series.reduce(
                        (acc, num) => acc + num,
                        0
                      );
                      return (
                        "$ " +
                        sumResult.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      );
                    },
                  },
                },
              },
            },
          },
          fill: {
            colors: ["#ff0"],
          },
        };
        if (total_usa == 0) {
          options_usa.fill.colors = ["rgba(255, 0, 0, 0.5)"];
        } else {
          delete options_usa.fill;
        }
        this.$refs.chart_usa?.updateOptions(options_usa);

        this.changeYearMonths();
        this.changeChartCountry();
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },

    async getCategories() {
      try {
        const { data } = await SettingsService.getCategory();
        this.categories = data;
      } catch (error) {
        console.log(error);
      }
    },

    async selectHandler() {
      try {
        this.addPreloader();
        this.filter.subcategory = null;
        if (this.filter.category == null) {
          this.subcategories = [];
          return;
        }
        const { data } = await LogisticInventoryService.getItemsSubcategory({
          category_id: this.filter.category,
        });
        this.subcategories = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },

    changeYearMonths() {
      this.$refs.towerChart?.updateOptions({
        title: {
          text:
            this.months[this.filter.monthSelected].value +
            ", " +
            this.filter.yearSelect,
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const formattedNumber = new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(series[seriesIndex][dataPointIndex]);
            return (
              '<div style="height:38px;background:none;"><div class=tooltip_chart> $ ' +
              formattedNumber +
              "</div></div>"
            );
          },
          fillSeriesColor: false,
          theme: false,
          style: {
            border: "none",
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            const formattedNumber = new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(val);
            return "$ " + formattedNumber;
          },
        },
      });

      setTimeout(() => {
        // document.getElementsByClassName("apexcharts-title-text")[0].setAttribute("x", "50");//set x position
        // var newElement = document.createElementNS("http://www.w3.org/2000/svg", "image");
        // newElement.setAttribute("href", "path/to/your/image.png");
        // newElement.setAttribute("x", "50");
        // newElement.setAttribute("y", "50");
        // newElement.setAttribute("width", "50");
        // newElement.setAttribute("height", "50");
        // const referenceElement = document.querySelector(".apexcharts-title-text p:nth-child(1)");
        // const parentElement = referenceElement.parentNode;
        // parentElement.insertBefore(newElement, referenceElement.nextSibling);
      }, "3000");
    },
    formatterFunc(value) {
      // Format the value to display two decimal places
      const formattedNumber = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
      return "$" + formattedNumber;
    },
    async changeChartCountry() {
      let options = {
        subtitle: {
          style: {
            color: this.isDarkSkin ? "#fff" : "#5D5D5D",
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          tickPlacement: "between",
          labels: {
            show: true,
            style: {
              colors: this.isDarkSkin ? "#adadad" : "#373d3f",
            },
          },
        },
        yaxis: {
          show: true,
          labels: {
            formatter: this.formatterFunc,
            style: {
              colors: [this.isDarkSkin ? "#adadad" : "#373d3f"],
            },
          },
        },
        dataLabels: {
          background: {
            enabled: true,
            foreColor: this.isDarkSkin ? "#fff" : "#800080",
            opacity: 1,
            borderColor: "rgba(255, 255, 255, 0)",
          },
        },
      };
      this.$refs.towerChart?.updateOptions(options);

      let data_dark = {
        plotOptions: {
          pie: {
            donut: {
              labels: {
                total: {
                  show: true,
                  color: this.isDarkSkin ? "#adadad" : "#373d3f",
                },
                value: {
                  color: this.isDarkSkin ? "#adadad" : "#373d3f",
                  formatter: function (val) {
                    const formattedNumber = new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(val);
                    return "$ " + formattedNumber;
                  },
                },
              },
            },
          },
        },
        legend: {
          labels: {
            colors: this.isDarkSkin ? "#adadad" : "#373d3f",
          },
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let total = series.reduce((a, b) => a + b, 0);
            const formattedNumber = new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(total);
            return (
              '<div style="height:38px;background:none;"><div class=tooltip_chart> $ ' +
              formattedNumber +
              "</div></div>"
            );
          },
        },
      };
      this.$refs.chart_usa?.updateOptions(data_dark);
      this.$refs.chart_peru?.updateOptions(data_dark);
    },
  },
};
</script>
<style scoped>
.data {
  font-size: 16px;
}

.data-mobile {
  font-size: 16px;
}
.title {
  font-weight: 600;
  font-size: medium;
}

.title-mobile {
  font-size: 12px !important;
}

.card {
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.all-text {
  color: #818181;
  font-size: large;
  padding-top: 5px;
}

@media (max-width: 1400px) {
  .title-mobile {
    font-size: 11px !important;
    margin-left: 15px !important;
  }

  .data {
    font-size: 14px;
  }

  .data-mobile {
    margin: 0.5px !important;
    font-size: 10.5px;
  }

  .icon {
    font-size: 60%;
  }

  .title {
    font-weight: 600;
    font-size: 13px;
  }

  .card {
    margin: 5px;
  }

  .all-text {
    color: #818181;
    font-size: large;
    padding-top: 5px;
  }
}
</style>
<style>
.title_echart {
  color: #007dc8;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.subtitle_echart {
  color: #adadad;
  font-size: 12px;
  font-weight: 700;
  width: 67px;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 3px 0 0 3px;
}
.flag {
  border-radius: 50px;
  width: 37px;
  height: 37px;
  background: #fff;
}
.verticalLines {
  background: #ff0;
}
.filters {
  background: #dceaff !important;
}
.vselect {
  background: #fff;
  border-radius: 5px;
}
.tooltip_chart {
  background: #484848;
  color: #fff;
  padding: 2px 12px;
  display: flex;
  justify-content: center;
  align-items: end;
  border-radius: 16px;
  margin-left: 90px;
}
.tooltip_chart::after {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 8px solid #484848;
  content: "";
  position: absolute;
  top: 24px;
}
.apexcharts-tooltip {
  background: rgb(255, 255, 255, 0);
  border: none;
  box-shadow: none;
}

.apexcharts-title-text::after {
  content: "h";
  border: solid 1px #f00;
  top: 5px;
  left: 5px;
  position: absolute;
}
.h_40 {
  height: 40px;
}
</style>