import { amgApi } from '@/service/axios';

// General servaices on module MetaMedia after refactoring
class LogisticInventory {
  async getCurrentInventoryStock(params) {
    const data = await amgApi.post('/logistics/inventory/get-current-inventory-stock', params);
    return data;
  }

  async getDetailedInventoryByItem(params) {
    const data = await amgApi.post('/logistics/inventory/get-detailed-inventory-by-item', params);
    return data;
  }

  async getItemsCategory() {
    const data = await amgApi.post('/logistics/inventory/get-items-category');
    return data;
  }

  async getItemsSubcategory(params) {
    const data = await amgApi.post('/logistics/inventory/get-items-subcategory', params);
    return data;
  }

  async getInventoryBrands() {
    const data = await amgApi.post('/logistics/inventory/get-inventory-brands');
    return data;
  }

  async saveItem(params) {
    const data = await amgApi.post('/logistics/inventory/save-item', params);
    return data;
  }

  async updateItem(params) {
    const data = await amgApi.post('/logistics/inventory/update-item', params);
    return data;
  }

  async getInventoryInput(params) {
    const data = await amgApi.post('/logistics/inventory/get-inventory-input', params);
    return data;
  }

  async getInventoryOutput(params){
    const data = await amgApi.post('/logistics/inventory/get-inventory-output', params);
    return data;
  }

  async getDetailedInput(params){
    const data = await amgApi.post('/logistics/inventory/get-detailed-input', params);
    return data;
  }

  async getItemDetails(params) {
    try {
      const { data } = await amgApi.post('/logistics/inventory/get-item-detail', params);
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  async getAllModules() {
      const data = await amgApi.get("/logistics/inventory/get-modules");
      return data.data;
  }
  async getItemsLogistic() {
    try {
      const data = await amgApi.get('/logistics/inventory/get-items-logistic');
      return data;
    } catch (error) {
      console.log('Error counterOffer');
    }
  }

  async getListUser(params) {
    try {
      const data = await amgApi.post('/logistics/inventory/get-list-user', params);
      return data;
    } catch (error) {
      console.log('Error counterOffer');
    }
  }

  async getItemsPredefinedSpecifications(params) {
    try {
      const data = await amgApi.post('/logistics/inventory/get-items-predefined-specifications', params);
      return data;
    } catch (error) {
      console.log('Error counterOffer');
    }
  }
  async updateValuesInItemDetail(params) {
    try {
      const data = await amgApi.post('/logistics/inventory/update-values-in-item-detail', params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async saveExistentItem(params) {
    try {
      const data = await amgApi.post('/logistics/inventory/add-existent-item', params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async updateAvailabilityStatus(params) {
    const data = await amgApi.post('/logistics/inventory/update_availability_status', params);
    return data;
  }

  async getAvailabilityStatusTracking(params) {
    const data = await amgApi.post('/logistics/inventory/get_tracking_availability_status', params);
    return data.data;
  }

  async updateConditionStatus(params) {
    const data = await amgApi.post('/logistics/inventory/update_condition_status', params);
    return data;
  }

  async getConditionStatusTracking(params) {
    const data = await amgApi.post('/logistics/inventory/get_tracking_condition_status', params);
    return data.data;
  }


  async getDepreciationByItemType(params) {
    try {
      const data = await amgApi.post('/logistics/inventory/get-depreciation-by-item-type', params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async deleteInventoryItem(params) {
    try {
      const data = await amgApi.post('/logistics/inventory/delete-inventory-item', params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

}

export default new LogisticInventory();
